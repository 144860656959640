<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-button variant="success" v-b-modal.new-modal>ثبت گروه کالا</b-button>
        <b-card title="گروه های کالا" class="mt-3">
            <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="title">اسم</label>
                                    <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <b-form-group label="وضعیت" label-for="status">
                                    <b-form-select id="status" v-model="searchParams.status">
                                        <b-form-select-option value="1">فعال</b-form-select-option>
                                        <b-form-select-option value="0">غیرفعال</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
                    <span class="badge badge-light" v-else>غیرفعال</span>
                </template>

                <template v-slot:cell(edit)="data">
                    <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
                </template>
                <template v-slot:cell(delete)="data">
                    <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i
                            class="fa fa-close"></i></button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>

        <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
        </pagination>
        <pagination :limit="2" v-else-if="items.data && search" :data="items.data"
            @pagination-change-page="searchItems">
        </pagination>

        <b-modal id="new-modal" title="ثبت گروه کالا" hide-footer>
            <div>
                <form @submit.prevent="newItem" id="new-item">
                    <b-form-group>
                        <label for="name"> اسم <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-input id="name" name="name" :disabled="disabled"></b-form-input>
                    </b-form-group>

                    <div class="form-group">
                        <label class="custom-switch">
                            <input type="checkbox" class="custom-switch-input" v-model="selected_status"
                                :disabled="disabled">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">وضعیت</span>
                        </label>
                    </div>
                    <input type="hidden" name="status" :value="selected_status == 'on' ? 1 : 0">
                    <div class="clearfix">
                        <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal id="edit-modal" title="ویرایش گروه کالا" hide-footer>
            <div v-if="edit.id">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <b-form-group>
                        <label for="edit-name"> اسم <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-input id="edit-name" name="name" v-model="edit.name" :disabled="disabled">
                        </b-form-input>
                    </b-form-group>

                    <div class="form-group">
                        <label class="custom-switch">
                            <input type="checkbox" class="custom-switch-input" v-model="edit.status"
                                :disabled="disabled">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">وضعیت</span>
                        </label>
                    </div>
                    <input type="hidden" name="status" :value="edit.status == 'on' || edit.status == 1 || edit.stats ? 1 : 0">
                    <div class="clearfix">
                        <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
                    </div>
                    <input type="hidden" value="PUT" name="_method">
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import mixins from './mixins/mixins'

    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/product_groups',
                title: 'گروه کالا',
                table_fields: [{
                        key: 'id',
                        label: 'شناسه '
                    },
                    {
                        key: 'name',
                        label: 'اسم'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ ثبت'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'edit',
                        label: 'ویرایش '
                    },
                    {
                        key: 'delete',
                        label: 'حذف'
                    }
                ],
                selected_status: '1',
                selected_filter_status: '',
            }
        },
        methods: {
            editItem(index){
            this.edit = {}
            let item = window.clone(this.items.data.data[index])
            item.index = index
            this.edit  = item
            this.edit.status = +this.edit.status
            this.$root.$emit('bv::show::modal','edit-modal')
        },
        },
        created() {
            this.loadItems()
            this.searchParams = {
                name: '',
                status: '',
            }
        }
    }
</script>